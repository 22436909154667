<template>
  <div class="fd-w-full">
    <h1 class="fd-text-lg fd-font-bold fd-text-black">{{ $t('invitations.rs.acceptTitle') }}</h1>
    <p class="fd-mr-2 fd-text-sm fd-font-normal" style="color: rgba(0, 0, 0, 0.71);">
      {{ $t('invitations.rs.acceptDescription') }}
    </p>

    <div class="fd-mb-24">
      <div class="fd-flex fd-items-center fd-mt-14">
        <div @click="form.personal = true" class="fd-flex fd-items-center" style="margin-right: 300px">
          <span
            class="fd-inline-block fd-mr-2 fd-overflow-hidden fd-w-5 fd-h-5 fd-cursor-pointer fd-border fd-border-theme-12 fd-rounded-full fd-bg-white fd-flex fd-items-center fd-justify-center"
            :style="[form.personal ? 'box-shadow: 0 0 5px rgba(40, 74, 51, 0.65);' : '']"
          >
            <span class="fd-inline-block fd-w-full fd-h-full fd-bg-theme-4" v-if="form.personal"></span>
          </span>
          <span class="fd-text-theme-9 fd-cursor-pointer">{{ $t('global.personal') }}</span>
        </div>

        <div @click="form.personal = false" class="fd-flex fd-items-center">
          <span
            class="fd-inline-block fd-mr-2 fd-overflow-hidden fd-w-5 fd-h-5 fd-cursor-pointer fd-border fd-border-theme-12 fd-rounded-full fd-bg-white fd-flex fd-items-center fd-justify-center"
            :style="[!form.personal ? 'box-shadow: 0 0 5px rgba(40, 74, 51, 0.65);' : '']"
          >
            <span class="fd-inline-block fd-w-full fd-h-full fd-bg-theme-4" v-if="!form.personal"></span>
          </span>
          <span class="fd-text-theme-9 fd-cursor-pointer">{{ $t('global.business') }}</span>
        </div>
      </div>
      <validation-observer ref="loginValidation">
        <b-form
          class="fd-mt-9"
          @submit.prevent
        >
          <b-form-group
            :label="$t('global.businessId')"
            label-for="businessId"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('global.businessId')"
              rules="required"
            >
              <b-form-input
                id="businessId"
                v-model="form.business_id"
                :state="errors.length > 0 ? false:null"
                name="businessId"
                :placeholder="$t('global.businessId')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <div class="fd-absolute fd-bottom-12 fd-right-12">
        <b-button
          type="submit"
          variant="primary"
          style="padding-left: 40px !important; padding-right: 40px !important;"
          @click="next"
        >
          {{ $t('global.next') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BFormGroup, BFormInput, BForm, BInputGroup, BButton } from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  name: "Accept",
  components: { BForm, BFormGroup, BFormInput, BInputGroup, BButton, ValidationProvider, ValidationObserver },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        personal: false,
        business_id: ''
      },
      required
    }
  },
  methods: {
    next() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$router.push({ name: 'invitations.rs.confirm' })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>